<template>
  <div class='admin-header'>
    <div class='icon-division' @click='moveToHome'>
      <img alt='home' src='~@/assets/images/sally-logo-full.svg'/>
      <div class="admin">{{ $t('admin') }}</div>
    </div>
    <div class="right-menu">
      <v-popover offset="3" placement="bottom-end" popoverClass="more-menu-group">
        <div class="more-button" :title="$t('icon.setting')"/>
        <template slot="popover">
          <div class="more-menu">
            <div class="item admin" @click="moveToHome">
              <div class="image"/>
              <span>{{ $t('home') }}</span>
            </div>
            <div class="item logout" @click="requestLogout">
              <div class="image"/>
              <span>{{ $t('log_out') }}</span>
            </div>
          </div>
        </template>
      </v-popover>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'AdminHeader',
  methods: {
    ...mapMutations(['logout']),
    moveToHome() {
      this.$router.push('/admin', () => {
      });
    },
    async requestLogout() {
      await this.logout();
      this.$router.push('/login', () => {
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';

.admin-header {
  position: fixed;
  top: 0;
  z-index: 998;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: $header_height_size;
  height: $header_height_size;
  color: $primary_color;
  width: 100%;
  background: $white;
  box-shadow: $header-shadow;

  .icon-division {
    margin-left: 24px;
    display: flex;
    cursor: pointer;

    img {
      height: 32px;
    }

    .admin {
      margin-top: 4px;
      font-size: $font_size_8;
      font-weight: 500;
      line-height: 1.38;
      letter-spacing: -0.2px;
      color: #00ca94;
    }
  }

  .right-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 28px;
    color: #7F7F7F;

    .v-popover {
      display: flex;
      align-items: center;
    }

    .more-button {
      cursor: pointer;
      width: 32px;
      height: 32px;
      background-size: 32px 32px;
      background-repeat: no-repeat;
      background-image: url("~@/assets/images/more.svg");

      &:hover {
        background-image: url('~@/assets/images/more_green.svg');
      }
    }

    .logout {
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: $max_width_size_for_mobile) {
  .admin-header {
    .icon-division {
      margin-left: 16px;

      img {
        width: 72px;
      }
    }

    .right-menu {
      margin-right: 16px;
    }
  }
}
</style>
<style lang="scss">
@import '~@/assets/style/variable.scss';

.more-menu-group {
  .more-menu {
    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px;
      font-size: $font_size_12;
      font-weight: $font_weight_400;
      letter-spacing: -0.3px;
      cursor: pointer;

      .image {
        width: 13px;
        height: 13px;
        background-size: 13px 13px;
        background-repeat: no-repeat;
        margin-right: 5px;
      }

      &.admin .image {
        background-image: url("~@/assets/images/admin.svg");
      }

      &.logout .image {
        background-image: url("~@/assets/images/logout.svg");
      }

      &:hover {
        background-color: rgba(0, 202, 148, 0.1);
        color: #00ca94;

        &.admin .image {
          background-image: url('~@/assets/images/admin_green.svg');
        }

        &.logout .image {
          background-image: url('~@/assets/images/logout_green.svg');
        }
      }
    }
  }
}
</style>
