<template>
<div class="side-bar menu">
  <router-link class="menu-item" to="/admin">
    <div>{{ $t('user.title') }}</div>
  </router-link>
</div>
</template>

<script>
export default {
  name: 'SideBar',
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';

.side-bar {
  height: 100%;
  &.menu {
    display: flex;
    flex-direction: column;
    min-width: 280px;
    height: 100%;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #2a324b;
    .menu-item {
      width: 100%;
      display: flex;
      align-items: center;
      height: 48px;
      font-size: $font_size_16;
      letter-spacing: normal;
      color: #ffffff;
      padding-left: 24px;
      border-left: 3px solid transparent;
      &.router-link-active {
        border-left: 3px solid #00ca94;
        color: #00ca94;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
  @media only screen and (max-width: $max_width_size_for_mobile) {
    &.menu {
      min-width: 70px;
      width: 70px;
      display: flex;
      .menu-item {
        padding-left: 25px;
        div {
          max-width: 10px;
          text-overflow: "";
          overflow: hidden;
        }
      }
    }
  }
}
</style>
