<template>
  <div class="admin">
    <admin-header/>
    <div class="body">
      <side-bar />
      <router-view />
    </div>
  </div>
</template>

<script>
import AdminHeader from '@/components/Header/AdminHeader.vue';
import SideBar from '@/views/Admin/SideBar.vue';

export default {
  name: 'Admin',
  components: {
    AdminHeader,
    SideBar,
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';

.admin {
  width: 100%;
  height: 100%;
  .body {
    margin-top: $header_height_size;
    display: flex;
    flex-direction: row;
    height: 100%;
  }
}
</style>
